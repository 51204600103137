<template>
  <div class="d-flex flex-column justify-content-between h-100">
    
<div class="">
    <div class="flow-header font-size-18 text-black">Trigger Setup</div>
    <div class="mt-3 flow-header font-size-14 text-grey text-uppercase" style="font-size:14px">Flow Trigger</div>
    <div class="flow-selection mt-3">
      <span class="font-size-14 text-grey">
        Which segment will trigger this flow?
      </span>
      <div class="mt-3">
        <div v-if="isLoading"
        class="integrationImgae bg-preload"
        style="height: 50px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
      <input v-else v-model="selectedDetail.title" type="text" name="" placeholder="Flow Name" class="flow-select " id="">
    </div>
   
      <div class="mt-4">
        <div
        class="form-group d-flex align-items-center row w-100 m-0"
       
      >
        <div class="col-12 p-0">
       

          <div
            class="roundedContainer d-flex align-items-center justify-content-between cursor-pointer mb-2"
            style="padding: 12px 16px 12px 16px"
            :class="selectedDetail.audience_type == 'all' ? 'selected' : null"
            @click="selectedDetail.audience_type='all'"
          >
            <span
              class="sidebarElementSubTitle"
              style="font-size: 15px;"
              :style="
                selectedDetail.audience_type == 'all' ? 'color:#4d1b7e;' : null
              "
              >All Quizzes Leads</span
            >
            <div>
              <svg
                v-if="selectedDetail.audience_type == 'all'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669ZM9.99996 5.83335C8.89489 5.83335 7.83508 6.27234 7.05368 7.05374C6.27228 7.83514 5.83329 8.89495 5.83329 10C5.83329 11.1051 6.27228 12.1649 7.05368 12.9463C7.83508 13.7277 8.89489 14.1667 9.99996 14.1667C11.105 14.1667 12.1648 13.7277 12.9462 12.9463C13.7276 12.1649 14.1666 11.1051 14.1666 10C14.1666 8.89495 13.7276 7.83514 12.9462 7.05374C12.1648 6.27234 11.105 5.83335 9.99996 5.83335Z"
                  fill="#4D1B7E"
                />
              </svg>

              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669Z"
                  fill="#73738D"
                />
              </svg>
            </div>
          </div>
          <div
            class="roundedContainer d-flex align-items-center justify-content-between cursor-pointer"
            style="padding: 12px 16px 12px 16px"
            :class="selectedDetail.audience_type == 'partial' ? 'selected' : null"
            @click="selectedDetail.audience_type='partial'"
          >
            <span
              class="sidebarElementSubTitle"
              style="font-size: 15px"
              :style="
                selectedDetail.audience_type == 'partial' ? 'color:#4d1b7e' : null
              "
              >Specific Leads</span
            >
            <div>
              <svg
                v-if="selectedDetail.audience_type == 'partial'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669ZM9.99996 5.83335C8.89489 5.83335 7.83508 6.27234 7.05368 7.05374C6.27228 7.83514 5.83329 8.89495 5.83329 10C5.83329 11.1051 6.27228 12.1649 7.05368 12.9463C7.83508 13.7277 8.89489 14.1667 9.99996 14.1667C11.105 14.1667 12.1648 13.7277 12.9462 12.9463C13.7276 12.1649 14.1666 11.1051 14.1666 10C14.1666 8.89495 13.7276 7.83514 12.9462 7.05374C12.1648 6.27234 11.105 5.83335 9.99996 5.83335Z"
                  fill="#4D1B7E"
                />
              </svg>

              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669Z"
                  fill="#73738D"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class=" mt-4" v-if="selectedDetail.audience_type=='partial'">
        <div v-if="isLoading"
                class="integrationImgae bg-preload"
                style="height: 50px; width: 100%; border-radius: 14px">
                <div class="into-preload"></div>
              </div>
          <select v-else class="flow-select" name="segments-list" v-model="specificAudienceType" >
            <option value="segment" >By Segment</option> 
             <option value="score" >By Score</option> 
  
 
</select>
      </div>
      <div v-if="selectedDetail.audience_type=='partial'">
      <!-- <MultiRangeSlider @change="(e)=>{selectedDetail.max_score=e.max; selectedDetail.min_score=e.min}"  class="mt-3"></MultiRangeSlider> -->
       <div class="mt-4" v-if="checkSpecificAudienceType=='score'&&specificAudienceType=='score'">
        <div class="" v-if="!isLoadingSegments">

<select class="flow-select" name="segments-list" v-model="selectedDetail.score_range_id" >
   <option :value="null" selected disabled >Select Score Range</option> 
<option :value="rang.id" v-for="rang in rangeList" :key="rang.id">{{rang.name}} (min:{{ rang.min_score }} max:{{ rang.max_score }})</option>

</select>


<div   class="link-add-new-email mt-4" @click="openCreateModal">Add new score range</div>


</div>
<div v-else
class="integrationImgae bg-preload"
style="height: 50px; width: 100%; border-radius: 14px">
<div class="into-preload"></div>
</div>
       </div>
      <div class=" mt-4" v-else>
        <div class="" v-if="!isLoadingRange">

          <select class="flow-select" name="segments-list" v-model="selectedDetail.segment_id" >
             <option :value="null" selected disabled >Select Segment</option> 
  <option :value="segment.id" v-for="segment in segments" :key="segment.id">{{segment.name}}</option>
 
</select>

<div   class="link-add-new-email mt-4" @click="openWindow">Add new segment</div>


          
        </div>
        <div v-else
        class="integrationImgae bg-preload"
        style="height: 50px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
       
</div>
      </div>
      </div>
    </div>
</div>
<div v-if="!isLoading" class="buttons d-flex align-items-center justify-content-between">

  <Button @click="$emit('cancel')" class="cancel-button" >Cancel</Button>
  <Button  @click="done()" :is-loading="isLoadingBtn" >Done</Button>
</div>

</div>
</template>

<script>
// import axios from 'axios';
// import MultiRangeSlider from '../../../Layout/MultiRangeSlider.vue';
import Button from '../../../Layout/Button.vue';
export default {
  props:{
    openCreateModal:Function,
    isLoadingBtn:Boolean,
    isLoading:Boolean,
    segments:Array,
    isLoadingRange:Boolean,
    rangeList:Array,
    loadSegments:Function,    

    
  
    selected:{
      type:Object,
      default:()=>({
    segment:{segment_name:'',
    segment_id:''},
    title:'',
    score_range_id:''
  })
    }
  },
components:{
  Button,
  
  // MultiRangeSlider
},
watch:{
  'selectedDetail.score_range_id'(newValue){
    const index = this.rangeList.findIndex(range=>range.id==newValue)
    if(index>-1)
  {
    const score= this.rangeList[index];
    this.selectedDetail.min_score=score.min_score;
    this.selectedDetail.max_score=score.max_score
  }
  },
  checkSpecificAudienceType:{
    handler(value)
    {
      this.specificAudienceType=value
    },
    immediate:true
  }
},
data(){
  return {
    isLoadingSegments:false,
      newWindow: null,
      checkInterval: null,
    selectedDetail:{...this.selected},
    // selectedDetail.audience_type:'all',
    range:{min:0,max:0},
    specificAudienceType:'segment',
    selectLastSegment:false,
  }
},
computed: {
  checkSpecificAudienceType(){
    return this.selectedDetail?.segment_id&&this.specificAudienceType=='segment'?'segment':'score'
  },
  },
methods:{
  openCreateScoreModal(){
    this.$refs.createRange.open()
  },
  openWindow() {
      this.newWindow = window.open('/manage/emails/segments/create?redirect=emailFlow', '_blank');

      // Start checking if the window is still open
      this.checkInterval = setInterval(this.checkWindowStatus, 500);

      // Listen for messages from the new window
      window.addEventListener('message', this.handleMessage);
    },
    checkWindowStatus() {
      if (this.newWindow.closed) {
        // Call the function when the new window is closed
        clearInterval(this.checkInterval);
        this.checkInterval = null; 
        this.handleWindowClosed();

        // Clear the interval to stop checking
        

        // Remove the event listener to avoid memory leaks
        window.removeEventListener('message', this.handleMessage);
      }
    },
    handleMessage(event) {
      // Ensure the message is coming from the expected origin
      if (event.origin !== new URL(this.windowUrl).origin) {
        return;
      }

      if (event.data === 'close') {
        // Close the window if the message is 'close'
        if (this.newWindow && !this.newWindow.closed) {
          this.newWindow.close();
        }
      
        this.handleWindowClosed();
      }
    },
    async handleWindowClosed() {
      this.isLoadingSegments=true;
      const selectLastSegment=true;
      await this.loadSegments(selectLastSegment);
      this.selectLastSegment=true;
      this.isLoadingSegments=false;
    },
done(){
  let detail = {...this.selectedDetail}
  if((this.selectedDetail.flowTitle!=''&&this.selectedDetail.id)&&((this.selectedDetail.segment_id&&this.selectedDetail.audience_type=='partial'&&this.specificAudienceType=='segment')||(this.selectedDetail.audience_type=='partial'&&this.specificAudienceType=='score')))
{
  if(this.selectedDetail.id!=''&&this.selectedDetail.audience_type=='partial'&&this.specificAudienceType=='segment')
{
  detail.max_score=0;
  detail.min_score=0;
}
if(this.selectedDetail.id!=''&&this.selectedDetail.audience_type=='partial'&&this.specificAudienceType=='score')
{
  detail.segment_id=null
}
  const segmentTitle =this.segments.find((segment)=>segment.id==this.selectedDetail.segment_id)?.name
  this.$emit('done',{...detail,segment_title:segmentTitle})
}
else if(this.selectedDetail.audience_type=='all'){
  detail.segment_id=null
  detail.max_score=detail.min_score=0
  this.$emit('done',{...detail,segment_title:''})
}
else
this.$toasted.show("Fill required fields",
{ theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 200})
}
},
updated(){
  if(this.selectLastSegment)
  this.selectedDetail.segment_id=this.selected.segment_id
},
mounted(){
 
}
}
</script>

<style scoped>
.flow-header{

font-style: normal;
font-weight: 500;
}
.text-grey{
  color: #73738d !important;;
}
.text-black{
   color: #1F1F28;
}
.font-size-18{
  font-size: 18px;
}
.font-size-14{
  font-size: 14px;
}
.flow-select{
  outline: none;
    border: none;
    border-bottom: 1px solid #B2AFB6;;
    width: 100%;
    padding: 6px 0px;
    transition:0.5s ease all
}
.flow-select:focus{

    border-bottom: 1px solid #4D1B7E;;
   
}
.cancel-button{
    background: white;
    border: 2px solid  #4D1B7E;
    color:#4D1B7E;
}
.text-quizell{
  color:#4D1B7E
}
 .border-quizell{
  border:1px solid #4D1B7E
 }
 .border-quizell:not(:disabled):not(.disabled):active{
 background: #4d1b7e !important; 
 color:white;
 box-shadow: none !important;
 }
 .link-add-new-email {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    text-align: left;
    cursor: pointer;
    flex: 1;
    width: 100%;
}
</style>